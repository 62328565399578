@import '../../../../App';

.container {
  height: 100%;
  padding: 24px 48px;

  @include responsive(lg-up) {
    padding: 24px 48px 24px 12px;
  }

  .title {
    margin: 0px 0px 16px 12px;
    ;
  }
}

.legendWrapper {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;

  .legendItem {
    display: flex;
    align-items: center;
    background-color: transparent !important;

    &:not(:last-of-type) {
      margin-right: 64px;
      color: color(primary-light);
    }

    &::before {
      content: "";
      width: 14px;
      height: 14px;
      border-radius: 50%;
      margin-right: 12px;
    }

    &.maintenance::before {
      background-color: color(primary-light);
    }

    &.failure::before {
      background-color: #00BDC7;
    }

    &.other::before {
      background-color: #949494;
    }
  }
}