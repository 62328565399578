@import '../../App';

.logo {
  width: 140px;
  height: auto;
}

.toggle {
  display: flex;
  margin-right: 20px;

  @include responsive(lg-up) {
    margin-right: 0;
  }
}

.container {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  max-width: $app-content-width;
  margin: 0 auto;
  background-color: color(secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: slideDown 1s ease-in-out forwards;

  @include responsive(sm-up) {
    flex-direction: row;
  }

  @include responsive(xl-up) {
    border-radius: 0 0 12px 12px;
  }
}

.highlighted {
  z-index: 1260;
  box-shadow: 0 0 0 3000px rgba(0, 0, 0, 0.4);
}

.darkModeHighlighted {
  z-index: 1260;
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%)
  }
}