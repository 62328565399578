@import '../../../../App.scss';

.container {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  height: 66px;
  border-radius: 4px;
  background-color: color(primary);
  justify-content: space-between;

  @include responsive(md-up) {
    padding: 8px 10px;
  }

  @include responsive(xl-up) {
    padding: 8px 20px;
  }

  .textBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 8px;
    flex: 1;

    &> :first-child {
      margin-bottom: 4px;
      white-space: nowrap;
    }

    .valueSkeleton {
      width: 48px;
      height: 22px;
    }
  }
}