@import '../../App.scss';

.tabs {
  :global(.MuiTab-root) {
    font-size: 20px;
    line-height: 36px;
    font-weight: 700;
    text-transform: inherit;
    color: color(secondary-light);
    padding: 0;
  }
}

.content {
  width: 1280px;
  height: 600px;
  padding: 32px;
  overflow: auto;

  &>* {
    display: none;

    &.active {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.container {
  display: contents;
}