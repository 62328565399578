@import '../../App.scss';

.container {
  display: block;

  @include responsive(lg-up) {
    display: flex;
    height: 326px;
  }

  .section {
    margin-bottom: 20px;

    @include responsive(lg-up) {
      height: 100%;
      width: 100%;
    }

    &:first-child {
      @include responsive(lg-up) {
        margin-right: 20px;
      }
    }
  }
}