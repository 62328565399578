.container {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 56px;
  border-radius: 12px;
  box-shadow: 0 7px 20px 5px rgba(85, 147, 176, 0.15);

  .text {
    margin-top: 16px;
  }
}