@import '../../App.scss';

.container {
    position: relative;
    border-radius: 12px;
    box-shadow: 0px 7px 20px 5px rgba(85, 147, 176, 0.15);
    background-color: color(secondary);
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-2%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.transitionEffect {
    animation: slideIn 1s ease-in-out forwards;
}

.headerContent {
    margin: 0.5%;
}