.accuracy {
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 4px;
}

.trendLabel {
  opacity: 0.4;
}

.bestAndWorstPrediction {
  display: flex;
  align-items: flex-start;
  margin-top: 32px;
}