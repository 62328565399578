@import '../../../App.scss';

.container {
  height: 100%;
  width: auto;
  padding: 12px 24px;

  @include responsive(lg-up) {
    padding: 24px 48px;
  }

  .title {
    margin: 0 0 16px 12px !important;
  }

  svg {
    overflow: visible;
  }
}