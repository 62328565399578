@import '../../../App.scss';

.openDialogSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 36px;
  padding-right: 36px;
  animation: slideLeft 1s ease-in-out forwards;

  .openDialogBtn {
    color: color(info);
    font-size: 16px;
    line-height: 36px;
    font-weight: 700;
    letter-spacing: 0.25px;
    text-transform: none;
    text-decoration: underline;
  }
}

.container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 22px;

  .documentUpload {
    position: relative;
    background-image: url('../assets/uploadBox.svg');
    height: 161px;
    width: 213px;
    cursor: pointer;
  }

  .dragOver {
    background-image: url('../assets/uploadBoxDragOver.svg');
  }

  .uploadText {
    position: absolute;
    top: 74%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: color(primary-main);
    font-size: 16px;
    width: 174px;
    height: 36px;
    text-align: center;

    .highlightedText {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .documentList {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .loadingOverlay {
      width: 286px;
    }

    .noDocument {
      font-size: 14px;
      font-weight: 400;
      color: color(primary-main);
      opacity: 0.8;
      width: 286px;
      display: flex;
      justify-content: center;
    }

    .pdfDocument {
      width: 286px;
      height: 46px;
      border: 1px solid color(info);
      border-radius: 8px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      gap: 14px;

      .pdfIcon {
        margin: 0 0px 0px 18px;
      }

      .title {
        font-size: 16px;
        font-weight: 600;
        width: 142px;
      }

      .fileSize {
        font-size: 14px;
        font-weight: 400;
        margin-left: 8px;
      }
    }
  }
}

.progress {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.failurePdfDocument {
  width: 286px;
  height: 46px;
  border: 1px solid #FF3D00;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 14px;

  .failurePdfIcon {
    margin: 0 0px 0px 18px;
  }

  .failureTitle {
    font-size: 16px;
    font-weight: 600;
    width: 142px;

    .failureSubtitle {
      font-size: 12px;
      color: #FF3D00;
    }
  }

  .reload {
    display: flex;
    margin-left: 28px;
  }
}