@import '../../App.scss';

@keyframes slideRight {
  0% {
    transform: translateX(-15%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(15%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 900px;

  @include responsive(lg-up) {
    flex-direction: row;
    height: 400px;
  }

  .leftSection {
    width: 100%;
    height: 50%;
    animation: slideRight 1s ease-in-out forwards;

    @include responsive(lg-up) {
      width: 50%;
      height: 100%;
    }
  }

  .rightSection {
    height: 50%;
    flex: 1;
    animation: slideUp 1s ease-in-out forwards;

    @include responsive(lg-up) {
      height: 100%;
    }
  }
}