@import '../../App';

.openJobsContent {
  position: relative;
  padding: 12px;

  .tableContainer {
    table {
      min-width: 700px;
      border-collapse: separate;
      border-spacing: 0 16px;
      padding: 0 16px;

      tbody tr {
        background-color: color(primary);
        border-radius: 10px;
        box-shadow: $shadow-light;

        &.dark-mode {
          box-shadow: $shadow-dark;
        }
      }

      th,
      td {
        font-size: 16px;
        font-weight: 400;
        border-bottom: none;
      }

      td:first-child {
        font-weight: 700;
      }

      th {
        font-size: 18px;
        padding-bottom: 0;
        font-weight: 700;
      }
    }

    table tbody tr td:first-child {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }

    table tbody tr td:last-child {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}

.overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
  cursor: pointer;

  .text {
    font-size: 20px;
    color: white;
  }
}
