@import '../../../../App.scss';

.container {
  flex: 1;
  display: flex;
  padding: 16px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  border-bottom: solid 8px;

  &:nth-child(1) {
    margin-right: 24px;
    border-color: color(success);
  }

  &:nth-child(2) {
    border-color: color(error);
  }

  &> :nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
  }

  .jobDiagnosis {
    flex: 1;
    margin-bottom: 20px;

    .diagnosisTitle {
      font-weight: 700;
      margin-bottom: 4px;
    }
  }

  .labels {
    display: flex;

    &> * {
      flex: 1;

      &:first-child {
        margin-right: 12px;
      }
    }

    .predictions {
      margin: 4px 0 0 0;
      padding-left: 16px;

      li:not(:last-child) {
        margin-bottom: 2px;
      }

      .predicted span {
        background-color: color(success-light);
      }

      .notPredicted span {
        background-color: color(error-light);
      }
    }
  }

  .performance {
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.05);
    color: color(secondary-light);
    border-radius: 4px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &.darkMode {
    background-color: #000000;

    .performance {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}