@import '../../../App.scss';

.container {
    display: flex;
    font-size: 14px;
    font-weight: 400;

    .contact {
        cursor: pointer;

        .arrowSvg {
            margin-right: 10px;
            margin-left: 10px;
        }
    }
}

.menu {
    padding: 28px 22px 28px 40px;
    border-radius: 4px;
    background-color: color(secondary);
    z-index: 1300;
    width: 434px;
    height: 264px;

    .astronaut {
        display: flex;

        .list {
            width: 70%;
            list-style-type: none;
            padding: 0 40px 0 0;
            margin: 18px 0 0 0;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .listItem {
                padding: 2px 0;
                transition: background-color 0.3s;
                cursor: pointer;
                display: flex;
                height: 32px;
                background-clip: content-box;
                align-items: center;

                &:not(:first-child) {
                    border-top: 1px solid rgba(0, 0, 0, 0.12);
                }
            }

            .listItem:hover {
                background-color: #d3d3d32a;
            }

            .darkModeListItem {
                &:not(:first-child) {
                    border-top: 1px solid #ffffff20;
                }
            }

            .darkModeListItem:hover {
                background-color: #d3d3d31f;
            }

            .text {
                margin-left: 12px;
            }

        }

        .takeTourContainer {
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 10px;

            .svgAndTitle {
                display: flex;
                margin: 4px 0px 0px 0px;
                align-items: center;
            }

            .svgAndTitle:hover {
                text-shadow: 2px 2px 10px #00BDC701;
            }

            .title {
                height: 16px;
                color: color(info);
                margin-left: 6px;
                cursor: pointer;
            }

            .title:hover {
                text-shadow: 0px 0px 8px #00bdc740;
            }
        }
    }
}

:global(.MuiMenu-paper) {
    box-shadow: 0px 7px 7px 0px rgba(141, 141, 141, 0.1);
}

:global(.MuiMenu-list) {
    padding: 0
}