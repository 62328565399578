@import '../../App.scss';

.container {
  height: 100vh;
  position: relative;

  @include responsive(sm-up) {
    padding: 32px 0;
  }

  @include responsive(xl-up) {
    padding: 56px 0;
  }

  .box {
    width: 100%;
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: color(background-paper);
    margin: 0 auto;

    @include responsive(sm-up) {
      width: 480px;
      height: auto;
      border-radius: 12px;
    }

    .logo {
      width: 158px;
      margin: 0 auto;
    }

    .button {
      color: color(primary-light);
      background-color: transparent;
      border: 1px solid #00bdc7;
      padding: 8px 76px;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.2px;
      font-weight: 700;
      width: 328px;
      text-transform: none;
      box-shadow: none;
      white-space: nowrap;
      gap: 12px;

      @include responsive(lg-up) {
        width: 348px;
      }
    }

    .help {
      margin-top: 24px;
      font-size: 14px;
      line-height: 20px;
      text-decoration: underline;
      cursor: pointer;
      color: color(info);
    }
  }

  .planet,
  .space {
    display: none;

    @include responsive(md-up) {
      display: block;
      position: absolute;
    }
  }

  .planet {
    width: 174px;
    bottom: 6%;
    left: 0;
  }

  .space {
    width: 230px;
    top: 0;
    right: 0;
  }

  .textField {
    border-radius: 8px;
    margin-top: 20px;
    transition: box-shadow 0.3s;
    border: 1px solid color(info);

    &:hover {
      box-shadow: 0 2px 4px 4px rgba(85, 147, 176, 0.1);
    }

    & fieldset {
      border: none;
    }

    :global(.MuiOutlinedInput-input) {
      padding: 2px;
      height: 36px;
    }
  }

  .textFieldDark {
    border: 1px solid #ffffff;
  }

  .separator {
    display: flex;
    align-items: center;
    width: 328px;
    margin: 12px 0;

    @include responsive(lg-up) {
      width: 348px;
    }
  }

  .separatorLine {
    flex: 1;
    height: 1px;
    width: 176px;
    background-color: color(secondary-light);
  }

  .separatorText {
    margin: 0 10px;
  }

  .loginButton {
    color: #ffffff;
    background: #00bdc7;
    width: 100%;
    margin-top: 6px;
    text-transform: none;
    box-shadow: none;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    font-weight: 700;
  }

  .loginButton:disabled {
    background: #e6e6e6;
  }

  .loginButtonDarkMode:disabled {
    background: #434343;
  }

  .rocketImg {
    margin-top: 4px;
    margin-bottom: 8px;
    height: 248px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > :nth-child(2) {
      margin-top: 12px;
    }
  }
  .sessionExpired {
    height: 34px;
    font-size: 14px;
    color: #ff3d00;
    width: 348px;
    text-align: center;
  }

  .form {
    width: 328px;

    @include responsive(lg-up) {
      width: 348px;
    }

    .validationText {
      color: #ff0000;
      font-size: 12px;
      margin-top: 8px;
      margin-left: 8px;
      height: 18px;
    }
  }

  .loading {
    color: #fff;
  }
}
