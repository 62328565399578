@import '../../../App.scss';

.container {
    width: 812px;
    display: flex;
    height: 80%;

    .info {
        display: flex;
        width: 50%;
        flex-direction: column;
        padding: 100px 0px 0px 66px;
    }

    .title {
        color: color(info);
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 16px;
    }

    .description {
        width: 360px
    }

    .astronautSvg {
        margin-top: 74px;
    }
}

.actions {
    display: flex;
    justify-content: space-between;

    .skipTitle {
        color: color(info);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-decoration-line: underline;
        margin: 62px 0px 54px 62px;
        cursor: pointer;
    }

    .onboardingButton {
        color: #ffffff;
        text-align: center;
        font-size: 14px;
        text-transform: none;
        font-weight: 500;
        line-height: 20px;
        background-color: color(info);
        width: 180px;
        height: 40px;
        margin: 48px;
    }
}