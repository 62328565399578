@import '../../App.scss';

@keyframes slideRight {
  0% {
    transform: translateX(-15%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(12%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.container {
  display: block;
  height: 900px;

  @include responsive(md-up) {
    display: flex;
    height: 436px;
  }

  .section {
    height: 436px;
    width: 100%;
    margin-bottom: 20px;

    @include responsive(md-up) {
      width: calc(50% - 10px);
    }

    &:first-child {
      margin-right: 20px;
    }

    .chart {
      position: relative;
      height: calc(100% - 106px);
      animation: slideRight 1s ease-in-out forwards;
    }
  }
}

.openDialogSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 36px;
  padding-right: 36px;
  animation: slideLeft 1s ease-in-out forwards;

  .openDialogBtn {
    color: color(info);
    font-size: 16px;
    line-height: 36px;
    font-weight: 700;
    letter-spacing: 0.25px;
    text-transform: none;
    text-decoration: underline;
  }
}

.dialog {
  width: auto !important;
  padding: 0 !important;
}