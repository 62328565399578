@import '../../App';

.fab {
  position: fixed;
  right: 16px;
  bottom: 16px;
  padding: 8px;
  margin: 0 !important;

  @include responsive(xl-up) {
    right: calc((100% - #{$app-content-width}) / 2);
  }
}
