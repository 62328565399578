.container {
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 12px;
  text-align: center;

  .content {
    width: 216px;

    &> :not(:last-child) {
      margin-bottom: 4px;
    }
  }
}