@import '../../../App.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include responsive(sm-up) {
    flex-direction: row;
  }

  @include responsive(lg-up) {
    justify-content: center;
    padding: 0 40px;
    flex: 1;
  }

  .dropDownFiltersContainer {
    width: 50%;

    @include responsive(sm-up) {
      display: flex;
      width: 100%;
      padding: 10px 0px 10px 14px;
    }

    @include responsive(lg-up) {
      display: contents;
    }
  }

  .dropDown {
    width: 100%;
    padding: 0px 0px 10px;
    margin-right: 14px;

    @include responsive(sm-up) {
      padding: 0px;
    }

    @include responsive(lg-up) {
      flex: 3;
      padding: 0px;
    }
  }

  .timeFilter {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 6;

    @include responsive(sm-up) {
      width: 32%;
      flex: none;
      align-items: flex-start;
    }

    @include responsive(lg-up) {
      display: block;
      flex: 6;
      width: 100%;
    }

  }
}