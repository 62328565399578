@import '../../../App.scss';

.toggle {
  width: 28px;
  height: auto;
  padding: 0;
  margin: 0 6px;
  color: #FFC200;

  @include responsive(lg-up) {
    margin: 0 16px;
  }
}