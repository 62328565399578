@import '../../App.scss';

.container {
  position: relative;
  padding: 48px 40px 32px 40px;

  @include responsive(sm-up) {
    width: $breakpoint-sm;
  }

  @include responsive(lg-up) {
    width: 688px;
  }

  .closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
}
