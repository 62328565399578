.container {
    margin: 10px;
    width: 104px;

    .dropdown {
        color: color(primary-light);

        :global(.MuiSelect-icon) {
            color: color(primary-light);
        }

        :global(.MuiOutlinedInput-notchedOutline) {
            border: none;
        }
    }
}