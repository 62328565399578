@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,300&display=swap');

// These are the default Material UI breakpoints.
// They cannot be exported from the theme into CSS variables,
// because CSS variables cannot be used inside media queries.
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1480px;

$app-content-width: 1440px;

$chatbot-header-height: 64px;
$chatbot-content-height: 66vh;
$chatbot-input-height: 56px;

// Up apply to resolutions above the breakpoint, while down apply to resolutions below the breakpoint.
// For example, if we want certain styles to only apply to phones, we would use sm-down (below 600px).
// It is best to follow mobile the first approach whenever possible, so the -up versions are preferred.
$breakpoints: (
  'sm-up': (
    min-width: $breakpoint-sm,
  ),
  'sm-down': (
    max-width: $breakpoint-sm,
  ),
  'md-up': (
    min-width: $breakpoint-md,
  ),
  'md-down': (
    max-width: $breakpoint-md,
  ),
  'lg-up': (
    min-width: $breakpoint-lg,
  ),
  'lg-down': (
    max-width: $breakpoint-lg,
  ),
  'xl-up': (
    min-width: $breakpoint-xl,
  ),
  'xl-down': (
    max-width: $breakpoint-xl,
  ),
) !default;

// Usage example: `@include responsive('md-up') { ...styles }`
@mixin responsive($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    +"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Usage example: `color: color(primary);`
// The names are the same as in material, they are exported in AppStyles.tsx.
@function color($color-name) {
  @return var(--color-#{$color-name});
}

$shadow-light: 0px 2px 7px rgba(0, 0, 0, 0.1);
$shadow-dark: 0px 6px 15px rgba(24, 62, 116, 0.15);

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: color(info);
}

p {
  margin: 0;
}

.with-right-aligned-legend {
  .recharts-legend-wrapper {
    transform: translateX(120%);
  }

  .recharts-legend-item:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

#smart-loop-ruler {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

input,
textarea,
button {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
