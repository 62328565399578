@import '../../../../App.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .kpis {
    margin-top: 12px;
  }
}

.card {
  position: relative;
  display: inline-flex;
  padding: 12px 0px 12px 38px;
  width: 284px;
  height: 90px;

  .titleContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding-left: 14px;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 6px;
  }

  .duration {
    font-size: 16px;
    font-weight: 700;
  }

  .description {
    margin-top: 6px;
  }

  .subtitle {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
  }

  .timeSpan {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding-left: 4px;
  }

  & > svg {
    position: absolute;
    bottom: -15px;
    left: -15px;
    transform: scaleX(1.1) scaleY(1.1);
  }
}

.firstCard {
  padding: 12px 12px 12px 12px;
}

.kpiValues {
  display: flex;
  border-bottom: 1px solid #00000025;
  padding: 4px;
  margin: 4px 8px 4px 0;
  width: 294px;

  &:last-child {
    border-bottom: none;
  }

  &.kpiValuesDarkMode {
    border-bottom: 1px solid #ffffff50;

    &:last-child {
      border-bottom: none;
    }
  }

  .name {
    flex: 0 1 46%;
  }

  .kpiValue {
    flex: 0 1 40%;
    text-align: center;
    padding-left: 2px;
  }

  .kpiPercentageUp,
  .kpiPercentageDown {
    flex: 0 1 18%;
    text-align: center;
  }

  .kpiPercentageDown {
    color: color(error);
  }

  .kpiPercentageUp {
    color: color(success);
  }

  .nullValue {
    font-size: 16px;
    color: color(primary-light);
  }

  .arrowIconDown {
    color: color(error);
    text-align: center;
    padding-left: 16px;
  }

  .arrowIconUp {
    color: color(success);
    text-align: center;
    padding-left: 16px;
  }

  .defaultIcon {
    text-align: center;
    font-size: 16px;
    padding-left: 16px;
  }
}
.workflowBottleneckTooltip {
  background: rgba(97, 97, 97, 1);
}
