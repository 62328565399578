@import '../../../../App.scss';

$medium-confidence-color: #FF8400;

.confidencesSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 12px 0;

  .confidencesBar {
    width: 700px;
    height: 48px;
    display: flex;
    margin-right: 12px;

    &> * {
      height: 100%;
    }

    &> :nth-child(1) {
      background-color: color(success);
    }

    &> :nth-child(2) {
      background-color: $medium-confidence-color;
    }

    &> :nth-child(3) {
      background-color: color(error);
    }
  }
}

.confidencesLegends {
  display: flex;

  &> * {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      margin-right: 6px;
    }
  }

  &> :nth-child(1) {
    margin-right: 80px;

    &::before {
      background-color: color(success);
    }
  }

  &> :nth-child(2) {
    margin-right: 80px;

    &::before {
      background-color: $medium-confidence-color;
    }
  }

  &> :nth-child(3) {
    &::before {
      background-color: color(error);
    }
  }
}