@import '../../../../App.scss';

.timeRanges {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include responsive(lg-up) {
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    margin-left: 16px;
  }

  .timeRange {
    color: color(primary-light);
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    width: 90px;
    padding: 12px;
    line-height: 16px;
    border: none;
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;

    &:not(:last-child) {
      @include responsive(lg-down) {
        margin: 10px;

        @media only screen and (min-width:600px) and (max-width:617px) {
          margin: 8px;
        }
      }

      @include responsive(lg-up) {
        margin-right: 12px;
      }
    }

    &.selected {
      color: #ffffff;
      background-color: color(info);
    }
  }
}