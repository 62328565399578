@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(20%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.container {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0;
    gap: 32px;
    justify-content: center;
    animation: slideUp 0.8s ease-in-out;
}