@import '../../../App.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;

  @include responsive(md-up) {
    flex-direction: row;
    padding: 12px 0 20px 20px;
    padding-right: 0;
  }

  @include responsive(xl-up) {
    padding: 12px 32px 20px;
  }

  @media only screen and (min-width:1300px) and (max-width:1359px) {
    gap: 24px;
  }

  @media only screen and (min-width:1360px) and (max-width:1479px) {
    gap: 24px;
  }


  .infoContainer {
    display: flex;
    align-items: center;
  }

  .infoCards {
    display: flex;

    .infoCardColumn {
      &:not(:last-of-type) {
        margin-right: 16px;

        @include responsive(xl-up) {
          margin-right: 24px;
        }
      }

      &> :not(:last-of-type) {
        margin-bottom: 26px;
      }
    }
  }

  .chartContainer {
    height: 228px;
    padding: 24px 0px;
    width: 180px;

    @include responsive(md-up) {
      width: 220px;
    }

    @include responsive(lg-up) {
      width: 180px;
    }

    @include responsive(xl-up) {
      width: 220px;
    }
  }
}

.title {
  margin: 24px 0px 0px 64px;
}