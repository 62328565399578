.container {
  height: 100%;
  padding: 24px 48px;

  .title {
    margin: 0px 0px 16px 12px;
  }

  svg {
    overflow: visible;
  }
}