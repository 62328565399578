@import '../../../../App.scss';

.container {
  margin: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 280px;
  height: 196px;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s;
  padding: 8px;
  background-color: var(--color-primary);

  &.trendIsNull {
    border-bottom: 12px solid #c7c5bd;
  }

  &.trendIsPositive {
    border-bottom: 12px solid color(success);
  }

  &.trendIsNegative {
    border-bottom: 12px solid color(error);
  }

  &:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  }
}

.title {
  margin: 12px 0 16px 0;
  padding: 0 16px;
}

.value {
  font-size: 36px !important;
  font-weight: 700 !important;
  margin-bottom: 20px !important;
  padding: 0 16px !important;
}

.trendImg {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #00000020;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.trendImgDarkMode {
    background-color: #ffffff40;
  }
}
.kpiTooltip {
  background: rgba(97, 97, 97, 1);
}
