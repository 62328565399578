@import '../../App.scss';

.container {
  position: relative;
  max-width: $app-content-width;
  padding: 20px 20px;
  margin: 0 auto;

  @media screen and (min-width:1480px) {
    padding: 20px 0;
  }

  &> :not(:last-child) {
    margin-bottom: 20px;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.feature {
  position: relative;
  animation: slideUp 1s ease-in-out forwards;
}

.highlighted {
  z-index: 1260;
  box-shadow: 0 0 0 3000px rgba(0, 0, 0, 0.4);
}

.darkModeHighlighted {
  z-index: 1260;
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
}

.transitionEffect {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.show {
  opacity: 1;
  animation: fadeIn 1s ease-in-out;
}

.hide {
  opacity: 0;
  animation: fadeOut 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}