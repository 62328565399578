@import '../../../App.scss';

.dialogContainer {
    position: fixed;
    background-color: color(secondary);
    z-index: 1270;
    border-radius: 8px;

    &.dialogContainerDarkMode {
        filter: drop-shadow(0px 7px 7px rgba(255, 255, 255, 0.2));

        &:after {
            content: url('../assets/pointerSvgDarkMode.svg');
        }
    }

    &:after {
        content: url('../assets/pointerSvg.svg');
        position: absolute;
        right: 30px;
        top: -40px;
    }

    .containerTour {
        position: relative;
        width: 434px;

        .title {
            color: color(primary-light);
            opacity: 0.5;
            font-size: 16px;
            font-weight: 400;
            line-height: 12px;
            margin: 36px 36px 20px 36px;
        }

        .subtitle {
            color: color(info);
            font-size: 20px;
            font-weight: 700;
            line-height: 22px;
            margin-left: 36px;
        }

        .description {
            width: 360px;
            margin-top: 8px;
            margin-left: 36px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .skip {
                color: color(info);
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                text-decoration-line: underline;
                margin-left: 36px;
                cursor: pointer;
            }

            .button {
                width: 90px;
                text-transform: none;
                margin: 28px 40px 28px 0;
                height: 40px;
                border-radius: 8px;
                background: color(info);
                color: #ffffff;
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
            }
        }
    }
}