@import '../../../App.scss';

.card {
    display: flex;
    height: 100vh;
}

.container {
    width: 48rem;
    margin: auto;
    border-radius: 12px;
    background-color: color(background-paper);
    padding: 16px;
}

.rocketImg {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 248px
}

.title {
    text-align: center !important;
    flex-grow: 1;
}

.description {
    text-align: center !important;
    margin: 24px !important;
}