@import '../../../../App.scss';

.appAutocomplete {
    border-radius: 8px;
    background-color: transparent;

    fieldset {
        border-radius: 8px;
        border-color: color(primary-light);
    }

    :global(.MuiOutlinedInput-notchedOutline) {
        border-color: color(primary-light) !important;
    }

    input {
        font-size: 14px;
    }

    input,
    button {
        color: color(primary-light);
    }

    &.optionSelected {
        background-color: color(info-dark);

        :global(.MuiOutlinedInput-notchedOutline) {
            border-color: color(info-dark) !important;
        }

        input,
        button {
            color: #fff;
        }
    }
}

.list {
    font-size: 14px;
}

.city {
    font-size: 12px;
}