@import '../../../App';

.title {
  margin-top: 12px;

  @include responsive(md-up) {
    margin-top: 0;
  }

  @include responsive(lg-up) {
    margin-top: 24px;
  }
}

.container {
  height: 100%;
  padding: 12px 24px 0 48px;

  @include responsive(sm-down) {
    padding: 12px 0 0 0;
  }

  @include responsive(md-up) {
    padding: 0;
  }

}