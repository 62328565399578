@import '../../../../App.scss';

.container {
  height: 100%;
  padding: 24px 48px;

  @include responsive(lg-up) {
    padding: 24px 12px 24px 48px;
  }

  .title {
    margin: 0px 0px 16px 12px;
  }

  svg {
    overflow: visible;
  }
}