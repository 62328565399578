@import '../../../App.scss';

@keyframes close {
  0% {
    max-height: 2000px;
    height: auto;
    opacity: 1;
  }

  100% {
    max-height: 0;
    opacity: 0;
  }
}

@keyframes opens {
  0% {
    transform: translateY(20%);
    max-height: 0;
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    max-height: 2000px;
    height: auto;
    opacity: 1;
  }
}

.cards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px 12px;
  animation: opens 0.8s ease-in-out forwards;

  @include responsive(lg-up) {
    padding: 24px 12px 24px 6px;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
  }

}

.filterSelected {
  animation: close 0.8s ease-in-out forwards;
}