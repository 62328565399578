@import '../../../App.scss';

.dialog {
    width: 936px;
    padding: 0;
    overflow: hidden;

    .iconAndTitle {
        border-bottom: 1px solid grey;
    }

    .equipmentName {
        padding: 24px 0 24px 32px;
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: flex-start;
    }

    .docUploadContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        .documentUploadDialog {
            position: relative;
            background-image: url('../assets/uploadBoxDialog.svg');
            height: 135px;
            width: 791px;
            cursor: pointer;
            margin-top: 36px;

            .uploadTextDialog {
                position: absolute;
                top: 74%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                color: color(primary-main);
                font-size: 16px;
                width: 244px;
                height: 28px;
                text-align: center;

                .highlightedTextDialog {
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }

        .documentUploadDialogDragOver {
            background-image: url('../assets/uploadBoxDialogDragOver.svg');
        }

        .docTableContainer {
            width: 812px;
            padding-top: 8px;

            .tableContainer {
                border-bottom: none;

                .pdfIconList {
                    margin-right: 24px;
                }

                table {
                    min-width: 700px;
                    border-collapse: separate;
                    border-spacing: 0 16px;
                    padding: 0 10px;
                    display: block;
                    overflow: hidden;
                }

                thead {
                    overflow: hidden;
                    width: 778px;
                    display: table;
                }

                tbody {
                    height: 30vh;
                    margin-bottom: 28px;
                    overflow-y: scroll;
                    display: block;

                    &::-webkit-scrollbar {
                        width: 6px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: rgba(0, 189, 199, 0.1);
                        border-radius: 12px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #00bdc7;
                        border-radius: 12px;
                    }
                }

                tbody tr {
                    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
                    margin-bottom: 8px;
                    display: table;
                    width: 774px;
                    border-spacing: 0 0;
                    border-radius: 10px;
                }

                td {
                    font-size: 16px;
                    font-weight: 400;
                    border-bottom: none;
                    padding: 16px;
                    border-top: 1px solid color(info);
                    border-bottom: 1px solid color(info);
                    width: 20%;
                }

                td:first-child {
                    font-weight: 700;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    border-left: 1px solid color(info);
                    width: 40%;
                }

                td:last-child {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    border-right: 1px solid color(info);
                }

                th {
                    font-size: 18px;
                    padding-bottom: 0;
                    font-weight: 700;
                    border-bottom: none;
                    padding-top: 0;
                }

                th:first-child {
                    padding-left: 64px;
                    width: 40%;
                }

                th:last-child {
                    padding-right: 4px;
                }

                th:nth-child(2) {
                    width: 20%;
                }
            }

        }

    }
}


.fileNameText {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.sortText {
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    margin-right: 14px;
}

.downloadAndDelete {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
    gap: 12px;
}

.sort {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sortDropDown div {
    border: 1px solid color(info);
    height: 40px;
    width: 124px;

    border-radius: 8px;
}

.sortDropDown div div {
    border: none;
    padding: 8px;
}

.sortDropDown fieldset {
    border: none;
}

.downloadIcon {
    cursor: pointer;
}

.overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
    cursor: pointer;

    .text {
        font-size: 20px;
        color: white;
    }
}

.noDocument {
    font-size: 14px;
    font-weight: 400;
    color: color(primary-main);
    opacity: 0.8;
    display: flex;
    top: 60%;
    position: absolute;
    right: 0;
    left: 0;
    justify-content: center;
}

.failedFilesTableCell {
    border-top: 1px solid #FF3D00 !important;
    border-bottom: 1px solid #FF3D00 !important;
}

.failedFilesTableCell:first-child {
    border-left: 1px solid #FF3D00 !important;
}

.failedFilesTableCell:last-child {
    border-right: 1px solid #FF3D00 !important;
}

.reload {
    display: flex;
    justify-content: flex-end;
    margin-right: 14px;
}

.failureSubtitleDialog {
    font-size: 12px;
    color: #FF3D00;
    line-height: 12px;
}

.progress {
    display: flex;
    justify-content: flex-end;
    margin-right: 14px;
}

.failureTextContainer {
    display: flex;
    flex-direction: column;
    gap: 4px
}