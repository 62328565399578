@import '../../App.scss';

@keyframes slideRight {
  0% {
    transform: translateX(-15%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(15%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.container {
  height: 780px;

  @include responsive(md-up) {
    display: flex;
    justify-content: space-between;
    height: 400px;
  }

  .leftSection {
    height: 400px;
    width: 100%;
    margin-right: 80px;
    animation: slideRight 1s ease-in-out forwards;

    @include responsive(md-up) {
      width: 50%;
      margin-right: 10px;
    }

    @include responsive(lg-up) {
      width: 57%;
      margin-right: 80px;
    }
  }

  .rightSection {
    height: 400px;
    flex: 1;
    padding: 24px 48px;
    animation: slideLeft 1s ease-in-out forwards;

    @include responsive(md-up) {
      padding: 24px 0px;
    }

    @include responsive(lg-up) {
      flex: 1;
      padding: 0px;
    }
  }
}